import React from 'react';
import styles from './AnnouncementBar.module.css';
import CountdownTimer from '../CountDownTimer/CountDownTimer';
import Heart from './Heart';

const AnnouncementBar = () => {
  return (
    <div className={styles.announcementBar}>
      <div className={styles.content}>
        <Heart className={styles.heartIcon} />
        Valentine's Sale: <span className={styles.highlight}>22% OFF</span>
        <a href="/u/65f8cd51-0f4f-426c-b928-2ac7700c38a8" className={styles.link}>
          Uturn
        </a>
        &
        <a href="/u/65f8c40f-8dbd-4b5b-a349-85cbc919546c" className={styles.link}>
          The Turn
        </a>
        listings + <span className={styles.highlight}>FREE Shipping</span>
      </div>
      <div className={styles.timer}>
        Ends in <CountdownTimer />
      </div>
    </div>
  );
};

export default AnnouncementBar;
